<template>
  <div>
    <div style="margin-top: 10px;margin-bottom: 0;" class="divider">
      <span style="color:#666;padding-left: 5px"
        ><v-icon style="color:#666!important">pie_chart</v-icon> Annual Fee
        Summary</span
      >
    </div>

    <apexchart
      v-if="annualFeeChart.data[0].data.length"
      width="100%"
      height="300"
      :options="annualFeeChart.options"
      :series="annualFeeChart.data"
    ></apexchart>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      annualFeeChart: {
        options: {
          chart: {
            id: "collection-report",
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: true,
          },
          stroke: {
            curve: "smooth",
          },
          xaxis: {
            categories: [
              "BAISAKH",
              "JESTHA",
              "ASAR",
              "SHRAWAN",
              "BHADRA",
              "ASOJ",
              "KARTIK",
              "MANGSIR",
              "POUSH",
              "MAGH",
              "FALGUN",
              "CHAITRA",
            ],
          },
        },
        data: [
          {
            name: "Collected",
            data: [],
          },
          {
            name: "Remaining",
            data: [],
          },
          {
            name: "Total",
            data: [],
          },
        ],
      },
      has_expired:false
    };
  },
   computed: {
    ...mapState(["paymentAlert"]),
  },
  mounted() {
    if(Object.keys(this.paymentAlert.pendingInvoice).length){
      this.has_expired = this.paymentAlert.pendingInvoice.is_expired
    }
    if(this.has_expired === false){
      this.annualFeeSummary();
    }
    //Comment on merging master 
    // this.annualFeeSummary();
  },
  methods: {
    ...mapActions(["getPendingPayment"]),

    annualFeeSummary() {
      let billMonth = [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
      ];
      this.$rest
        .get("/api/report/billing/annual-collected")
        .then(({ data }) => {
          const collected = [];
          const due = [];
          const total = [];
          let allData = {};
          data.map((item) => {
            billMonth.map((bill_month) => {
              if (item.bill_month === bill_month.toString()) {
                allData[bill_month.toString()] = {
                  collected: item.collected,
                  due: item.remaining,
                  total: item.total,
                };
              }
            });
          });

          billMonth.map((bill_month) => {
            if (!["", undefined, null].includes(allData[bill_month])) {
              collected.push(allData[bill_month].collected.toFixed(2));
              due.push(allData[bill_month].due.toFixed(2));
              total.push(allData[bill_month].total.toFixed(2));
            } else {
              collected.push("0");
              due.push("0");
              total.push("0");
            }
          });
          // console.log(collected,due,total);
          this.annualFeeChart.data[0].data = collected;
          this.annualFeeChart.data[1].data = due;
          this.annualFeeChart.data[2].data = total;
        });
    },
  },
};
</script>
